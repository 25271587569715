import React, { useState, useEffect, useRef } from 'react';
import Header from "../component/Header"


function ChannelList(props) {


    return (
        <React.Fragment>
            <div className="ms-main-container loaded" style={{ opacity: 1 }}>
                <div className="ms-preloader"></div>
                <Header headeClass="ms-header" />
                <main className="ms-container">
                    {/* Page Title */}
                    <div className="ms-section__block">
                        <div className="ms-page-title">
                            <h2 className="page-header">Let's build together</h2>
                            </div>
                    </div>
                    {/* Page Content */}
                    <div className="ms-section__block">
                        <div id="contact" className="row">
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h6>email</h6>
                                        <p>info@yellowwhale.ventures</p>
                                    </div>
                                    {/*<div className="col-md-6 cont-soc">
                                        <h6>social</h6>
                                        <p>
                                            <a href="#" className="socicon-facebook" />
                                            <a href="#" className="socicon-instagram" />
                                            <a href="#" className="socicon-twitter" />
                                        </p>
    </div>*/}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <h6>Get in touch</h6>
                                <form id="validForm">
                                    <div className="row">
                                        <div className="form-group col-md-6">
                                            <input type="text" name="name" className="form-control" id="cl" placeholder="Your name" autoComplete="off" />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <input type="email" name="email" className="form-control" placeholder="Email address" autoComplete="off" />
                                        </div>
                                        <div className="form-group col-md-12">
                                            <input type="text" name="subject" className="form-control" placeholder="Subject" />
                                        </div>
                                        <div className="form-group col-md-12">
                                            <textarea name="message" className="form-control" id="message" placeholder="Your message" defaultValue={""} />
                                        </div>
                                        <div className="col-md-12">
                                            <div className="ms-button" data-title="send">Send
                  <input type="submit" defaultValue="send" />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </main>
                {/* Footer */}
                <footer>
                    <div className="ms-footer">
                        <div className="copyright">Copyright © 2021. All Rights Reserved Yellow Whale Labs</div>
                        <ul className="socials">
                            <li><a href="#" className="socicon-facebook" /></li>
                            <li><a href="#" className="socicon-twitter" /></li>
                            <li><a href="#" className="socicon-instagram" /></li>
                            <li><a href="#" className="socicon-youtube" /></li>
                        </ul>
                    </div>
                </footer>
            </div>

        </React.Fragment>
    );

}

export default ChannelList;
