import React, { useState, useEffect, useRef } from 'react';
import Header from "../component/Header"


function ChannelList(props) {


    return (
        <React.Fragment>
            <div className="ms-main-container loaded" style={{opacity:1}}>
                <div className="ms-preloader"></div>
                <Header headeClass="ms-header"/>
                {/* Container */}
                <main className="ms-container">
                    {/* Page Title */}
                    <div className="ms-section__block">
                        <div className="ms-page-title">
                            <h2 className="page-header">who we are?</h2>
                            <p className="page-desc">Aspiring developers and tech lovers into Web3 and Gaming.</p>
                        </div>
                    </div>
                    {/* Page Content */}
                    <div className="ms-section__block">
                        <div id="about" className="row">
                            <div className="about__img col-md-12">
                                {/*<p><img src="assets/images/14about.jpg" /></p>*/}
                            </div>
                            <div className=" center-block">
                                <div className="about__info col-md-8">
                                    <p>Joined hands together as a team who believe in the power of blockchain and its usecase, Yellow Whale Labs formed to bring best and curated web3 and gaming focused products to the end users.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                {/* Footer */}
                <footer>
                    <div className="ms-footer">
                        <div className="copyright">Copyright © 2021. All Rights Reserved Yellow Whale Labs</div>
                        <ul className="socials">
                            <li><a href="#" className="socicon-facebook" /></li>
                            <li><a href="#" className="socicon-twitter" /></li>
                            <li><a href="#" className="socicon-instagram" /></li>
                            <li><a href="#" className="socicon-youtube" /></li>
                        </ul>
                    </div>
                </footer>
            </div>

        </React.Fragment>
    );

}

export default ChannelList;
