import React, { useState, useEffect, useRef } from 'react';
import Header from "../component/Header"
import Sidebar from "../component/Sidebar"
import { Link } from 'react-router-dom';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    classNames: "testing"
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    classNames: "testing"
  },
  mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      classNames:"testing"
  }
};

function Home() {
  const carouselRef = useRef()
  const [sliderIndex, setSliderIndex] = useState(0)

  const CustomButtonGroupAsArrows = ({ next, previous, ...rest }) => {
    const { carouselState: { currentSlide } } = rest;
    setSliderIndex(currentSlide)
    console.log("currentSlidecurrentSlide", currentSlide)
    return (
      <div className="swiper-nav-btn">
        {(currentSlide != 2 && currentSlide != 6) && <div className="swiper-button-prev swiper-button-white" onClick={() => previous()}>prev</div>}
        {(currentSlide === 2 || currentSlide === 6) && <div className="swiper-button-prev swiper-button-white swiper-button-disabled" >prev</div>}
        <div className={`swiper-button-next swiper-button-white ${currentSlide === 4 && 'swiper-button-disabled'}`} onClick={() => next()}>next</div>

      </div>

    );
  };

  useEffect(() => {

    let slickListDiv = document.getElementsByClassName('react-multi-carousel-list')[0]
    slickListDiv.addEventListener('wheel', e => {
      if (e.deltaX !== 0 || e.deltaY !== 0) {
        e.preventDefault();
        if (e.deltaX >= 10 || e.deltaY >= 10) {
          if (carouselRef.current.state.currentSlide != 4)
            {carouselRef.current.next()}
        }
        if (e.deltaX <= -10 || e.deltaY <= -10) {
          if (carouselRef.current.state.currentSlide != 2)
            carouselRef.current.previous()
        }
      }

    })


  }, [])

  return (
    <div >
      {/* Preloader */}
      <div className="ms-preloader" />
      <Header headeClass="ms-header navbar-white" />
      <main className="ms-container home-slider">
        <div className="swiper-container">

          <Carousel
            swipeable={true}
            ref={carouselRef}
            plugins={['arrows']}
            draggable={false}
            slidesToSlide={1}
            keyBoardControl={false}
            responsive={responsive}
            infinite={true}
            arrows={false}
            customButtonGroup={<CustomButtonGroupAsArrows />}
            className="swiper-wrapper"
          >
            <div className="swiper-slide">
              <div className="slide-inner" data-swiper-parallax="45%">
                <div className="overlay"></div>
                <div className="slide-inner--image" style={{ backgroundImage: `url('../assets/images/2.jpg')` }}></div>
                <div className="slide-inner--info">
                <h1 style={{fontSize:"60px"}}>yellow whale <br/>labs </h1>
                  <Link to='products' data-type="page-transition" className="ms-btn--slider">take a look</Link>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="slide-inner" data-swiper-parallax="45%">
                <div className="overlay"></div>
                <div className="slide-inner--image" style={{ backgroundImage: `url('../assets/images/1.jpg')` }}>
                </div>
                <div className="slide-inner--info">
                  <h1 style={{fontSize:"60px", color:"white"}}>In-game<br />assets showcase</h1>
                  <Link to='products' data-type="page-transition" className="ms-btn--slider">take a look</Link>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="slide-inner" data-swiper-parallax="45%">
                <div className="overlay"></div>
                <div className="slide-inner--image" style={{ backgroundImage: `url('../assets/images/3.jpg')` }}>
                </div>
                <div className="slide-inner--info">
                  <h1 style={{fontSize:"60px"}}>into the<br />metaverse/game</h1>
                  <Link to='products' data-type="page-transition" className="ms-btn--slider">take a look</Link>
                </div>
              </div>
            </div>
            {/* <div className="swiper-slide">
              <div className="slide-inner" data-swiper-parallax="45%">
                <div className="overlay"></div>
                <div className="slide-inner--image" style={{ backgroundImage: `url('../assets/images/4.jpg')` }}>
                </div>
                <div className="slide-inner--info">
                  <h1>What<br />the Fog?</h1>
                  <Link to='products' data-type="page-transition" className="ms-btn--slider">take a look</Link>
                </div>
              </div>
            </div> */}

          </Carousel>


          <div className="expanded-timeline">
            <div className="expanded-timeline__counter"><span>{sliderIndex === 2 ? 1 : sliderIndex === 3 ? 2 : sliderIndex === 4 ? 3 :  1}</span></div>
            <div className="swiper-pagination swiper-pagination-progress">
              <span class="swiper-pagination-progressbar" style={{ "transform": `translate3d(0px, 0px, 0px) scaleX(${sliderIndex === 1 ? '0.25' : sliderIndex === 2 ? '0.4' : sliderIndex === 3 ? '0.65' : '1'}) scaleY(1)`, "transition-duration": "1000ms" }}></span>
            </div>
            <div className="scroll-message">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.5 15.98" className="scroll-svg"><title>Asset 1</title><g>
                <g data-name="Layer 1"><polygon fill="#fff" points="0 4.64 0.71 5.34 3.85 2.2 3.85 15.98 4.85 15.98 4.85 2.2 8 5.34 8.71 4.64 4.35 0.29 0 4.64"></polygon><polygon fill="#fff" points="11.65 0 11.65 13.79 8.5 10.64 7.79 11.35 12.15 15.7 16.5 11.35 15.79 10.64 12.65 13.79 12.65 0 11.65 0"></polygon></g></g></svg>Scroll
            </div>
          </div>
        </div>
      </main>

    </div>
  );

}

export default Home;
