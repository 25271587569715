import React, { useState, useEffect, useRef } from 'react';
import Header from "../component/Header"
import Sidebar from "../component/Sidebar"


function UserList(props) {

const [scale,setScale]=useState(0)
const [index,setIndex]=useState(0)
const handleScale =(id)=>{
    setScale(id)
    setIndex(id)
}
    return (
        <React.Fragment>
            <div className="ms-main-container loaded" style={{opacity:1}}>
                <div className="ms-preloader"></div>
                <Header headeClass="ms-header"/>
                <main className="ms-container">
                    {/* Page Title */}
                    <div className="ms-section__block">
                        <div className="ms-page-title">
                            <h2 className="page-header">OUR PRODUCTS</h2>
                            <p className="page-desc">DApps and Games</p>
                        </div>
                    </div>
                    {/* Page Content */}
                    <div className="ms-section__block">
                        <div id="products">
                            {/* Sort */}
                            <ul className="filtr-btn">
                                <li data-filter="all" className={`${scale===0 && 'active'}`} onClick={()=>handleScale(0)}><h6>all</h6></li>
                                </ul>
                            <div className="filtr-container row">
                                {/* Album item */}
                                {(scale=== 0 ||scale===1 )&&
                                <div className={`album-item col-md-4 filtr-item ${(scale !=1||scale !=0) &&'filteredOut'}`} data-category={1} style={{'transform-style': 'preserve-3d',transform:`scale(${(scale===1||scale===0)? '1':'0.5'}) translate3d(0px, 0px, 0px)`,opacity:(scale===1||scale===0)?'1':'0'}}>
                                    <a href="https://www.pickleswap.exchange" target="_blank"> 
                                        <div className="album-item__overlay">
                                            <span className="album-item__cover" />
                                            <h5>Pickleswap</h5>
                                        </div>
                                        <div className="album-item__img" style={{ backgroundImage: `url('../assets/images/3a.jpg')`}} />
                                    </a>
                                </div>}
                                
                                {(scale=== 0 ||scale===2 )&&
                                <div className="album-item col-md-4 filtr-item" data-category={4} style={{'transform-style': 'preserve-3d',transform:`scale(${(scale===2||scale===0)? '1':'0.5'}) translate3d(0px, 0px, 0px)`}}>
                                    <a href="https://www.hiddenbeyond.xyz" target="_blank">
                                        <div className="album-item__overlay">
                                            <span className="album-item__cover" />
                                            <h5>Hidden Beyond</h5>
                                        </div>
                                        <div className="album-item__img" style={{ backgroundImage: `url('../assets/images/1a.jpg')`}} />
                                    </a>
                                </div>}
                                
                                {(scale=== 0 ||scale===2 )&&
                                <div className="album-item col-md-4 filtr-item" data-category={2} style={{'transform-style': 'preserve-3d',transform:`scale(${(scale===2||scale===0)? '1':'0.5'}) translate3d(0px, 0px, 0px)`}}>
                                        <a href="https://www.rapidrush.xyz" target="_blank">
                                        <div className="album-item__overlay">
                                            <span className="album-item__cover" />
                                            <h5>Rapid Rush</h5>
                                        </div>
                                        <div className="album-item__img" style={{ backgroundImage: `url('../assets/images/4a.jpg')`}} />
                                    </a>
                                </div>}
                                {/*
                                {(scale=== 0 ||scale===3 )&&
                                <div className="album-item col-md-4 filtr-item" data-category={3} style={{'transform-style': 'preserve-3d',transform:`scale(${(scale===3||scale===0)? '1':'0.5'}) translate3d(0px, 0px, 0px)`}}>
                                    <a href="gallery.html" data-type="page-transition">
                                        <div className="album-item__overlay">
                                            <span className="album-item__cover" />
                                            <h5>What the Fog?</h5>
                                        </div>
                                        <div className="album-item__img" style={{ backgroundImage: `url('../assets/images/4a.jpg')`}} />
                                    </a>
                                </div>}

                                {(scale=== 0 ||scale===3 || scale ===4)&&
                                <div className="album-item col-md-4 filtr-item" data-category={4} style={{'transform-style': 'preserve-3d',transform:`scale(${(scale===3||scale===4||scale===0)? '1':'0.5'}) translate3d(0px, 0px, 0px)`}}>
                                    <a href="gallery.html" data-type="page-transition">
                                        <div className="album-item__overlay">
                                            <span className="album-item__cover" />
                                            <h5>project #36</h5>
                                        </div>
                                        <div className="album-item__img" style={{ backgroundImage: `url('../assets/images/5a.jpg')`}} />
                                    </a>
                                </div>}

                                {(scale=== 0  || scale ===4)&&
                                <div className="album-item col-md-4 filtr-item" data-category={4} style={{'transform-style': 'preserve-3d',transform:`scale(${(scale===4||scale===0)? '1':'0.5'}) translate3d(0px, 0px, 0px)`}}>
                                    <a href="gallery.html" data-type="page-transition">
                                        <div className="album-item__overlay">
                                            <span className="album-item__cover" />
                                            <h5>adidas: 360°</h5>
                                        </div>
                                        <div className="album-item__img" style={{ backgroundImage: `url('../assets/images/6a.jpg')`}} />
                                    </a>
                                </div>}

                                {(scale=== 0 ||scale===3 )&&
                                <div className="album-item col-md-4 filtr-item" data-category={3} style={{'transform-style': 'preserve-3d',transform:`scale(${(scale===3||scale===0)? '1':'0.5'}) translate3d(0px, 0px, 0px)`}}>
                                    <a href="gallery.html" data-type="page-transition">
                                        <div className="album-item__overlay">
                                            <span className="album-item__cover" />
                                            <h5>People of India</h5>
                                        </div>
                                        <div className="album-item__img" style={{ backgroundImage: `url('../assets/images/7a.jpg')` }} />
                                    </a>
                                </div>}

                                {(scale=== 0  || scale ===4)&&
                                <div className="album-item col-md-4 filtr-item" data-category={4} style={{'transform-style': 'preserve-3d',transform:`scale(${(scale===4||scale===0)? '1':'0.5'}) translate3d(0px, 0px, 0px)`}}>
                                    <a href="gallery.html" data-type="page-transition">
                                        <div className="album-item__overlay">
                                            <span className="album-item__cover" />
                                            <h5>Soho House Barcelona</h5>
                                        </div>
                                        <div className="album-item__img" style={{ backgroundImage: `url('../assets/images/8a.jpg')` }} />
                                    </a>
                                </div>}

                                {(scale=== 0 ||scale===1 )&&
                                <div className="album-item col-md-4 filtr-item" data-category={1} style={{'transform-style': 'preserve-3d',transform:`scale(${(scale===1||scale===0)? '1':'0.5'}) translate3d(0px, 0px, 0px)`}}>
                                    <a href="gallery.html" data-type="page-transition">
                                        <div className="album-item__overlay">
                                            <span className="album-item__cover" />
                                            <h5>With Charleen </h5>
                                        </div>
                                        <div className="album-item__img" style={{ backgroundImage: `url('../assets/images/9a.jpg')` }} />
                                    </a>
                                </div>}*/}
                            </div>
                        </div>
                    </div>
                </main>
                {/* Footer */}
                <footer>
                    <div className="ms-footer">
                        <div className="copyright">Copyright © 2021. All Rights Reserved Yellow Whale Labs</div>
                        <ul className="socials">
                            <li><a href="#" className="socicon-facebook" /></li>
                            <li><a href="#" className="socicon-twitter" /></li>
                            <li><a href="#" className="socicon-instagram" /></li>
                            <li><a href="#" className="socicon-youtube" /></li>
                        </ul>
                    </div>
                </footer>
            </div>

        </React.Fragment >
    );

}

export default UserList;
