import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function Header(props) {
 const [drawer, setDrawer] = useState(false);
 console.log(props, "props:::");

 return (
  <React.Fragment>
    <header className={props.headeClass}>
      <nav className={`ms-nav ${drawer && 'is-visible'}`}>
        <div className="ms-logo">
          <Link to='/' data-type="page-transition">
            <div className={`logo-dark ${props.headeClass !== 'ms-header navbar-white' && 'current'}`}>
              <img src={require("../assets/images/logo-dark.png")} alt="Dark logo" />
            </div>
            <div className={`logo-light ${props.headeClass === 'ms-header navbar-white' && 'current'}`}>
              <img src={require("../assets/images/logo-light.png")} alt="Light logo" />
            </div>
          </Link>
        </div>
        <button className={`hamburger ${drawer && 'is-active'}`} type="button" data-toggle="navigation" onClick={() => setDrawer(!drawer)}>
          <span className={`hamburger-box`} onClick={() => setDrawer(!drawer)}>
            <span className="hamburger-label">menu</span>
            <span className="hamburger-inner" />
          </span>
        </button>
        <div className="height-full-viewport">
          <ul className="ms-navbar">
            {/* Nav Link */}
            <li className="nav-item">
              <Link to="/" data-type="page-transition">
                <span className="ms-btn">home</span>
                <span className="nav-item__label">Back to home page</span>
              </Link>
            </li>
            {/* Nav Link */}
            <li className="nav-item">
              <Link to='products' data-type="page-transition">
                <span className="ms-btn">products</span>
                <span className="nav-item__label">View all our works</span>
              </Link>
            </li>
            {/* Nav Link */}
            <li className="nav-item">
              <Link to='about' data-type="page-transition">
                <span className="ms-btn">about</span>
                <span className="nav-item__label">Learn more about the author</span>
              </Link>
            </li>
            {/* Nav Link */}
            <li className="nav-item">
              <Link to='contact' data-type="page-transition">
                <span className="ms-btn">contact</span>
                <span className="nav-item__label">Get in touch and find us</span>
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  </React.Fragment>
 );
}

export default Header;
