import React from 'react';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import Home from "./container/Home";
import Products from "./container/Products";
import About from './container/About';
import Contact from './container/Contact';

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route exact path='/' element={<Home />} />
				<Route exact path='/products' element={<Products />} />
				<Route exact path='/about' element={<About />} />
				<Route exact path='/contact' element={<Contact />} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;
