import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './assets/css/style.css'
 
// import '../assets/css/plugins.min.css'

ReactDOM.render(
	<React.Fragment>
		<App />
	</React.Fragment>,
	document.getElementById('root')
);

